'use strict';

function hide_background_menu() {
    jQuery('#header').removeClass('show_background');
}
function show_background_menu() {
    jQuery('#header').addClass('show_background');
}

(function(jQuery) {

  jQuery('#show_mini_menu').click(function(e) {
      hide_background_menu();
      show_background_menu();
  });

})(jQuery); // Fully reference jQuery after this point.

jQuery( window ).resize(function() {
    if(jQuery( window ).width() <= 991) {
        hide_background_menu();
        show_background_menu();
    }
    else {
        hide_background_menu();
    }
});

jQuery( window ).resize();